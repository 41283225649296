@charset "utf-8";

// Import partials from `sass_dir` (defaults to `_sass`)
// Includes Bourbon, Neat, and Bitters (thanks, thoughtbot!)
@import
  "bourbon/bourbon",
  "base/base",
  "neat/neat",
  "layout"
;

p {
    font-size: 18px !important
}

.wrapper {
    max-width: 70em !important;
}

.fct-actnow {
    text-align: center
}